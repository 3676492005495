import React, {useState, useEffect, memo} from 'react';
import PropTypes from 'prop-types';
import {DayPicker} from 'react-day-picker';
import useLocalizationLoadFile from 'helpers/hooks/useLocalizationLoadFile';

const DayFullPicker = ({value, before, after, initMonth, onChange, toggleDatePicker}) => {
  const [datePicker, setDatePicker] = useState(value ?? initMonth ?? after);
  const dayPickerLocale = useLocalizationLoadFile();

  useEffect(() => {
    if (value) {
      setDatePicker(value);
    }
  }, [value]);

  const handleDateClick = (date) => {
    onChange(date);
    toggleDatePicker();
  };

  return (
    <DayPicker
      captionLayout="dropdown"
      selected={value}
      fromDate={before}
      toDate={after}
      locale={dayPickerLocale}
      onDayClick={handleDateClick}
      month={datePicker}
      onMonthChange={(date) => setDatePicker(date)}
    />
  );
};

DayFullPicker.propTypes = {
  value: PropTypes.object,
  before: PropTypes.object.isRequired,
  after: PropTypes.object.isRequired,
  initMonth: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  toggleDatePicker: PropTypes.func.isRequired
};

export default memo(DayFullPicker);
