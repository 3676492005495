import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import * as R from 'ramda/src/index';
import classnames from 'classnames';
import popover from 'helpers/Popover';
import {
  FieldContainer,
  Label,
  Radio,
  FieldErrors
} from './components';

const RadioGroupField = ({
  floatingLabel,
  inputName,
  value,
  radios,
  disabled,
  inline,
  labelProps,
  htmlFor,
  errors,
  containerProps,
  errorsContainerProps,
  popoverProps,
  onChange
}) => {
  const hasError = !!errors.length;
  const wrapperClass = classnames(
    'radio', {'d-inline-flex mr-3': inline, 'mb-2': !inline}
  );

  return (
    <FieldContainer
      {...containerProps}
      value={value}
      floatingLabel={floatingLabel}
      hasError={hasError}
    >
      {
        radios.map((radio, i) => (
          <div key={i} className={wrapperClass}>
            <Label {...labelProps} htmlFor={htmlFor}>
              <Radio
                name={inputName}
                value={value}
                radioValue={radio.value}
                disabled={disabled}
                onChange={onChange}
              />
              {R.isEmpty(popoverProps) ? radio.label : popover(
                {...popoverProps[i], triggerNode: <span>{radio.label}</span>}
              )}
            </Label>
          </div>
        ))
      }
      <FieldErrors {...errorsContainerProps} errors={errors} />
    </FieldContainer>
  );
};

RadioGroupField.propTypes = {
  floatingLabel: PropTypes.bool,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired,
  htmlFor: PropTypes.string,
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  containerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  popoverProps: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

RadioGroupField.defaultProps = {
  floatingLabel: false,
  inputName: '',
  value: '',
  radios: [],
  errors: [],
  // Not required properties
  disabled: false,
  htmlFor: null,
  containerProps: {},
  inputProps: {},
  labelProps: {},
  errorsContainerProps: {},
  popoverProps: []
};

export default memo(RadioGroupField);
