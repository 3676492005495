import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import classnames from 'classnames';
import popover from 'helpers/Popover';
import {FieldErrors, Label} from './components';

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

const CheckboxField = ({option, checked, name, label, labelProps, htmlFor, errors, errorsContainerProps, onChange}) => {
  const hasError = Boolean(errors.length);
  const containerClasses = classnames('custom-checkbox', {'has-error': hasError});

  return (
    <div className={containerClasses}>
      <Label {...labelProps} htmlFor={htmlFor} className='d-flex'>
        <div className="mr-2">
          <input
            className="custom-checkbox-input"
            type="checkbox"
            name={name}
            value={option}
            checked={checked}
            onChange={onChange}
          />
          <span className="custom-checkbox-label"></span>
        </div>
        {isString(label) ? label : popover({...label})}
      </Label>
      {hasError && <FieldErrors {...errorsContainerProps} errors={errors} />}
    </div>
  );
};

CheckboxField.propTypes = {
  option: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  htmlFor: PropTypes.string,
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired
};

CheckboxField.defaultProps = {
  errors: [],
  // Not required properties
  htmlFor: null,
  labelProps: {},
  errorsContainerProps: {}
};

export default CheckboxField;
