import {language, bookingBaseUrl} from 'helpers/stableValues';

export const BOOKING_RECEIPT_TO_INVOICE = 'booking-receipt-to-invoice';
export const BOOKING_CANCEL_REFUND = 'booking-cancel-refund';
export const BOOKING_TICKETS_OPEN = 'booking-tickets-open';

export const ferryRoute = `/${language}/${bookingBaseUrl}`;
export const baseRoute = `/${language}/user`;
export const verifyAccountRoute = `${baseRoute}/verify-account`;
export const verifyEmailRoute = `${baseRoute}/verify-email`;
export const recoverPasswordRoute = `${baseRoute}/recover-password`;
export const resetPasswordRoute = `${baseRoute}/reset-password`;
export const boardingRoute = `${baseRoute}/boarding`;
export const dashboardRoute = `${baseRoute}/dashboard`;
export const dashboardBookingRoute = `${baseRoute}/dashboard/:bookingID`;
export const profileRoute = `${baseRoute}/profile`;
export const bookingsRoute = `${baseRoute}/bookings`;
export const bookingCancelRefundRoute = `${baseRoute}/bookings/:bookingID/cancel-refund`;
export const bookingTicketsOpenRoute = `${baseRoute}/bookings/:bookingID/open-ticket`;
export const passengersRoute = `${baseRoute}/passengers`;
export const newPassengersRoute = `${baseRoute}/passengers/new`;
export const editPassengersRoute = `${baseRoute}/passengers/:passengerID/edit`;
export const cardsRoute = `${baseRoute}/cards`;
export const addressesRoute = `${baseRoute}/addresses`;
export const newAddressesRoute = `${baseRoute}/addresses/new`;
export const editAddressesRoute = `${baseRoute}/addresses/:addressID/edit`;
export const articlesRoute = `/${language}/blog`;
export const manageBookingRoute = `${baseRoute}/manage-booking`;
export const manageBookingRoutes = `${baseRoute}/manage-booking/*`;
export const manageBookingBookingRoute = `${baseRoute}/manage-booking/booking`;
export const manageInvoiceBookingRoute = `${baseRoute}/manage-booking/receipt-to-invoice`;
export const manageCancelBookingRoute = `${baseRoute}/manage-booking/cancel-refund`;
export const manageOpenBookingRoute = `${baseRoute}/manage-booking/open-ticket`;
export const manageBookingDetailsRoute = `${baseRoute}/manage-booking/details`;

export const getRoute = ({key, bookingID}) => {
  switch (key) {
  case BOOKING_RECEIPT_TO_INVOICE:
    return `${baseRoute}/bookings/${bookingID}/receipt-to-invoice`;
  case BOOKING_CANCEL_REFUND:
    return `${baseRoute}/bookings/${bookingID}/cancel-refund`;
  case BOOKING_TICKETS_OPEN:
    return `${baseRoute}/bookings/${bookingID}/open-ticket`;
  default:
    return baseRoute;
  }
};
