import React from 'react';
import {observer} from 'mobx-react';
import {ToastContainer, toast} from 'react-toastify';
import postal from 'postal';
import {isAirtickets} from 'helpers/stableValues';

const uid = function () {
  let counter = 0;
  return () => counter++;
}();

const notificationsChannel = postal.channel('notifications');

notificationsChannel.subscribe('notify', function (data) {
  const options = {
    toastId: uid(),
    type: data.type,
    position: 'top-center',
    autoClose: 8000,
    draggable: false,
    className: 'toast-' + data.type + (isAirtickets ? '-airtickets' : ''),
    theme: 'dark',
    icon: false
  };

  toast(data.message, options);
});

const showNotification = (message, type = 'success') => {
  notificationsChannel.publish('notify', {message, type});
};

const Notifications = observer(() => (
  <ToastContainer />
));

const notifications = () => (
  <Notifications />
);
notifications.displayName = 'Notifications';

export default notifications;
export {notificationsChannel, showNotification};
