import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import {
  FieldContainer,
  Textarea,
  Label,
  FieldErrors
} from './components';

const TextareaField = ({
  floatingLabel,
  textareaName,
  value,
  label,
  onChange,
  errors,
  htmlFor,
  placeholder,
  containerProps,
  textareaProps,
  labelProps,
  errorsContainerProps
}) => {
  const hasError = !!errors.length;

  return (
    <FieldContainer
      {...containerProps}
      value={value}
      floatingLabel={floatingLabel}
      hasError={hasError}
    >
      <Textarea
        {...textareaProps}
        name={textareaName}
        value={value}
        placeholder={placeholder || label}
        hasError={hasError}
        onChange={onChange}
      />
      <Label {...labelProps} htmlFor={htmlFor}>{label}</Label>
      <FieldErrors {...errorsContainerProps} errors={errors} />
    </FieldContainer>
  );
};

TextareaField.propTypes = {
  floatingLabel: PropTypes.bool,
  textareaName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  htmlFor: PropTypes.string,
  placeholder: PropTypes.string,
  containerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  textareaProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  })
};

TextareaField.defaultProps = {
  floatingLabel: true,
  inputName: '',
  value: '',
  label: '',
  errors: [],
  // Not required properties
  htmlFor: null,
  placeholder: null,
  container: {},
  textareaProps: {},
  labelAttr: {},
  errorsContainerProps: {}
};

export default memo(TextareaField);
