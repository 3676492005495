import Translation from 'lib/helpers/translation';

const userAccountTranslations = {
  ...Translation.group('userAccount')
};

const verifyAccountTranslations = {
  ...Translation.group('verifyAccount')
};

const verifyEmailTranslations = {
  ...Translation.group('verifyEmail')
};

const recoverPasswordTranslations = {
  ...Translation.group('recoverPassword')
};

const resetPasswordTranslations = {
  ...Translation.group('resetPassword')
};

const testPasswordTranslations = {
  ...Translation.group('testPassword')
};

const userSidebarTranslations = {
  ...Translation.group('userSidebar')
};

const userBoardingTranslations = {
  ...Translation.group('userBoarding')
};

const userDashboardTranslations = {
  ...Translation.group('userDashboard')
};

const userBookingsTranslations = {
  ...Translation.group('userBookings')
};

const userPassengerTranslations = {
  ...Translation.group('userPassenger')
};

const userVehicleTranslations = {
  ...Translation.group('userVehicle')
};

const userCardTranslations = {
  ...Translation.group('userCard')
};

const userAddressTranslations = {
  ...Translation.group('userAddress')
};

const userProfileTranslations = {
  ...Translation.group('userProfile')
};

const manageBookingLoginTranslations = {
  ...Translation.group('manageBookingLogin')
};

const manageBookingHomeTranslations = {
  ...Translation.group('manageBookingHome')
};

const userCommonTranslations = {
  ...Translation.group('userCommon')
};

const userErrorMessagesTranslations = {
  ...Translation.group('errorMessages')
};

export {
  userAccountTranslations,
  verifyAccountTranslations,
  verifyEmailTranslations,
  recoverPasswordTranslations,
  resetPasswordTranslations,
  testPasswordTranslations,
  userSidebarTranslations,
  userBoardingTranslations,
  userDashboardTranslations,
  userBookingsTranslations,
  userPassengerTranslations,
  userVehicleTranslations,
  userCardTranslations,
  userAddressTranslations,
  userProfileTranslations,
  manageBookingLoginTranslations,
  manageBookingHomeTranslations,
  userCommonTranslations,
  userErrorMessagesTranslations
};
