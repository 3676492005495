function removeURLParameter(url, parameter) {
  let urlparts= url.split('?');
  if (urlparts.length >= 2) {
    const prefix= encodeURIComponent(parameter)+'=';
    let pars= urlparts[1].split(/[&;]/g);

    for (let i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    return url;
  } else {
    return url;
  }
}

function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getParams(url) {
  if (!url) {
    return {};
  }
  const urlparts = url.split('#')[0].split('?')[1] || '';
  if (!urlparts) {
    return {};
  }
  return urlparts
    .split('&')
    .reduce((params, param) => {
      let [ key, value ] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
}

export {removeURLParameter, getParameterByName, getParams};
