import React, {memo} from 'react';
import PropTypes from 'prop-types';

const Form = ({name, id, className, onSubmit, children}) => {
  return (
    <form
      name={name}
      id={id}
      className={className}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};

Form.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Form.defaultProps = {
  name: null,
  id: null,
  className: null,
  children: null
};

export default memo(Form);
